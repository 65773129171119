import React from "react";
import Navbar from "./Navbar";
import ProjectTile from "./ProjectTile";
import projects from "./static/projects.json";
import research from "./static/research.json";
import presentations from "./static/presentations.json";

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="container mx-auto p-4">
        <header
          id="home"
          className="relative bg-gradient-to-r from-blue-800 to-blue-900 text-white py-12 px-6 rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row items-center"
        >
          {/* Profile Picture Section */}
          <div className="relative z-10 mb-6 md:mb-0 md:mr-6 flex-shrink-0">
            <img
              src="/images/profile.png"
              alt="Profile"
              className="w-32 h-32 md:w-40 md:h-40 rounded-full border-4 border-white shadow-lg"
            />
          </div>

          {/* Text Section (Name, Title, and Links) */}
          <div className="relative z-10 text-center md:text-left flex-1">
            {/* Name and Title Section */}
            <blockquote className="text-4xl md:text-5xl font-extrabold mb-4 leading-tight">
              <span className="before:block before:absolute before:-inset-1 before:-skew-y-2 before:bg-gray-800 relative inline-block">
                <span className="relative text-white">Kartikeya Sharma</span>
              </span>
            </blockquote>
            <p className="text-xl md:text-2xl font-light mb-6">
              Software Engineer, Researcher, and Cybersecurity Graduate
            </p>

            {/* Links Section */}
            <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6">
              <a
                href="https://github.com/sha256rma"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-300 hover:text-blue-500 transition duration-300 ease-in-out text-lg font-medium rounded-full border border-blue-300 px-4 py-2"
              >
                GitHub
              </a>
              <a
                href="https://scholar.google.com/citations?user=Lei9wgwAAAAJ&hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-300 hover:text-blue-500 transition duration-300 ease-in-out text-lg font-medium rounded-full border border-blue-300 px-4 py-2"
              >
                Google Scholar
              </a>
              {/* <a
                href="mailto:"
                className="text-blue-300 hover:text-blue-500 transition duration-300 ease-in-out text-lg font-medium rounded-full border border-blue-300 px-4 py-2"
              >
                Email
              </a> */}
            </div>
          </div>
        </header>

        <section id="presentations" className="mb-12">
          <blockquote class="text-3xl font-bold italic mt-8 mb-4 text-slate-900">
            Industry Presentations ({Object.keys(presentations).length})
          </blockquote>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {presentations.map((presentations, index) => (
              <ProjectTile
                key={`Presentations ${index + 1}`}
                image={presentations.image}
                title={presentations.title}
                description={presentations.description}
                link={presentations.link}
                linkText={presentations.linkText}
                videos={presentations.videos}
                tags={presentations.tags}
                date={presentations.date}
              />
            ))}
          </div>
        </section>

        <section id="research" className="mb-12">
          <blockquote class="text-3xl font-bold italic mt-8 mb-4 text-slate-900">
            Academic Research ({Object.keys(research).length})
          </blockquote>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {research.map((research, index) => (
              <ProjectTile
                key={`Research ${index + 1}`}
                image={research.image}
                title={research.title}
                description={research.description}
                link={research.link}
                linkText={research.linkText}
                videos={research.videos}
                tags={research.tags}
                date={research.date}
              />
            ))}
          </div>
        </section>

        <section id="projects" className="mb-12">
          <blockquote class="text-3xl font-bold italic mt-8 mb-4 text-slate-900">
            Projects ({Object.keys(projects).length})
          </blockquote>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {projects.map((project, index) => (
              <ProjectTile
                key={`Project ${index + 1}`}
                image={project.image}
                title={project.title}
                description={project.description}
                link={project.link}
                linkText={project.linkText}
                videos={project.videos}
                tags={project.tags}
                date={project.date}
              />
            ))}
          </div>
        </section>
      </div>
      <footer className="bg-gray-800 text-white py-4 text-center">
        <p className="text-sm">
          &copy; 2024 Kartikeya Sharma. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
