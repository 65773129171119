import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectTile = ({
  image,
  videos,
  title,
  description,
  link,
  linkText,
  tags,
  date,
  key,
}) => {
  const formatDescription = (text) => {
    if (!text) return "";
    return text.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: videos.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 transition-transform transition-shadow duration-300 hover:shadow-md hover:bg-gray-50">
      {image ? (
        <img
          src={image}
          alt={title}
          className="w-full h-56 object-cover bg-gray-100"
        />
      ) : videos && videos.length > 0 ? (
        <Slider {...settings} key={key}>
          {videos.map((video, index) => (
            <div key={index} className="relative pb-56">
              <iframe
                className="absolute inset-0 w-full h-full"
                src={video}
                title={`${title} Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="w-full h-56 flex items-center justify-center bg-gradient-to-r from-gray-800 via-gray-700 to-gray-600">
          <p className="text-white font-semibold">No Media</p>
        </div>
      )}
      <div className="p-6">
        <h2 className="text-xl font-bold mb-3">{title}</h2>
        <p className="text-gray-600 mb-4">{formatDescription(description)}</p>
        {date && <p className="text-gray-400 text-sm mb-4">{date}</p>}
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 font-semibold"
          >
            {linkText}
          </a>
        ) : (
          <p className="text-blue-600 font-semibold">{linkText}</p>
        )}
        <div className="mt-4 flex flex-wrap gap-3">
          {tags &&
            tags.map((tag, index) => (
              <span
                key={index}
                className="bg-blue-100 text-gray-600 px-3 py-1 rounded-full text-sm font-medium"
              >
                {tag}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectTile;
