import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-800 p-4 shadow-md sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-extrabold text-white">Portfolio</h1>
        <div className="hidden md:flex space-x-6">
          <a
            href="#home"
            className="text-white hover:text-yellow-300 transition duration-300"
          >
            Home
          </a>
          <a
            href="#presentations"
            className="text-white hover:text-yellow-300 transition duration-300"
            onClick={toggleMenu}
          >
            Industry Presentations
          </a>
          <a
            href="#research"
            className="text-white hover:text-yellow-300 transition duration-300"
          >
            Academic Research
          </a>
          <a
            href="#projects"
            className="text-white hover:text-yellow-300 transition duration-300"
          >
            Projects
          </a>
          {/* <a
            href="#contact"
            className="text-white hover:text-yellow-300 transition duration-300"
          >
            Contact
          </a> */}
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
            aria-label="Toggle navigation"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={`M4 6h16M4 12h16m-7 6h7`}
                className={`transition-transform duration-300 ${
                  isOpen ? "hidden" : ""
                }`}
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`md:hidden fixed inset-0 bg-blue-800 bg-opacity-90 z-50 transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col items-center pt-16">
          <button
            onClick={closeMenu}
            className="absolute top-4 right-4 text-white text-3xl focus:outline-none"
            aria-label="Close menu"
          >
            &times;
          </button>
          <a
            href="#home"
            className="text-white text-xl py-2 hover:text-yellow-300"
            onClick={toggleMenu}
          >
            Home
          </a>
          <a
            href="#presentations"
            className="text-white text-xl py-2 hover:text-yellow-300"
            onClick={toggleMenu}
          >
            Industry Presentations
          </a>
          <a
            href="#research"
            className="text-white text-xl py-2 hover:text-yellow-300"
            onClick={toggleMenu}
          >
            Academic Research
          </a>
          <a
            href="#projects"
            className="text-white text-xl py-2 hover:text-yellow-300"
            onClick={toggleMenu}
          >
            Projects
          </a>
          {/* <a
            href="#contact"
            className="text-white text-xl py-2 hover:text-yellow-300"
            onClick={toggleMenu}
          >
            Contact
          </a> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
